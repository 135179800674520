import React from "react"

import "./july-offer-tnc.scss"

import offers from './offerData.js';

const JulyOffer2024Tnc = () => {
  return (
    <>
      <div className="main-content">
        <div className="title" style={{ textAlign: "center" }}>
          JULY DHAMAKA SALE OFFER TERMS & CONDITIONS
        </div>
        <p className="sub-heading">DEFINITIONS</p>
        <p>
          For the purpose of these terms and conditions (“
          <b>Terms and Conditions</b>”):
        </p>
        <ul style={{ listStyle: "disc" }}>
          <li>
            <p>
              <b>“User”</b> shall mean a business entity that has On-Boarded on
              the SOLV Platform using its legal name and mobile number, and is
              legally subsisting during the Offer Period;
            </p>
          </li>
          <li>
            <p>
              <b>“Eligible User”</b> shall mean a User which satisfies the
              conditions of this Offer and becomes eligible to receive the
              discounts as set out in this Offer;
            </p>
          </li>
          <li>
            <p>
              <b>“FMCG and HoReCa Products”</b> means fast moving consumer
              goods, both food and non-food products, processed food or hotel,
              restaurant and catering related products;
            </p>
          </li>
          <li>
            <p>
              <b>“Consumer Electronic Products”</b> means mobile accessories and feature phones;
            </p>
          </li>
          <li>
            <p>
              <b>“Offer”</b> shall mean the various discount schemes offered to
              the Eligible Users for buying specified (i) FMCG or HoReCa products ; (ii) Consumer Electronic products; (iii) Apparel and fashion products; (iv) Home furnishing products; and (v) Footwear products, as detailed below
              during the Offer Period in the eligible pin codes;
            </p>
          </li>
          <li>
            <p>
              <b>“Offer Period”</b> shall mean the period as set out in the
              table below, during which the Eligible Users shall be entitled to
              avail the respective Offer.
            </p>
          </li>
          <li>
            <p>
              <b>“On-Board” or “On-Boarding”</b> means the downloading and
              installation of the SOLV application by the User, providing
              relevant Merchant Information to SOLV, successful verification of
              Merchant Information and registration of User as a seller/buyer on
              the SOLV Platform by obtaining a unique SOLV identification
              number;
            </p>
          </li>
          <li>
            <p>
              <b>“Primary Terms and Conditions”</b> shall mean the terms and
              conditions governing the usage of the SOLV platform, as applicable
              to the Users as separately provided on the SOLV portal and/or
              application in addition to these Terms and Conditions set out
              herein.
            </p>
          </li>
        </ul>
        <p>
          Any capitalised terms used in these Terms and Conditions, however not
          defined herein, shall have the same meanings as attributed to such
          terms in the Primary Terms and Conditions.
        </p>
        <p className="sub-heading">OFFERS AND CONDITIONS:</p>
        <p className="sub-heading">A. FMCG or HoReCa Products</p>
        <div className="table-responsive des-tos">
          <table
            className="table table-bordered"
            style={{ width: "100%", margin: "0px auto" }}
          >
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>Sr. No.</th>
                <th>Offer Period</th>
                {/* <th>Campaign</th> */}
                <th>Product Type for offer</th>
                <th>Buyer Location</th>
                <th>Minimum order value (Rs.)</th>
                <th>Discount Value</th>
                <th>No. of coupons</th>
                <th>Coupon Codes</th>
                <th>Details</th>
              </tr>
              {offers['fmcg'].map((offer) => {
                return <tr>
                  <td style={{ textAlign: "center" }}> {offer.srNo}</td>
                  <td >{offer.offerPeriod} </td>
                  <td >{offer.type}</td>
                  <td > {offer.location} </td>
                  <td style={{ textAlign: "right" }}>{offer.mov}</td>
                  <td style={{ textAlign: "right" }}>{offer.discount}</td>
                  <td style={{ textAlign: "center" }}>{offer.noOfCoupons}</td>
                  <td style={{ textAlign: "center" }}>{offer.code}</td>
                  <td>
                    <b>{offer.Details}</b>
                    <br></br>
                    {offer.subDetails}
                  </td>
                </tr>
              })}


            </tbody>
          </table>
        </div>

        <p className="sub-heading">B. Apparel products (July Fashion Fair)</p>
        <div className="table-responsive des-tos">
          <table
            className="table table-bordered"
            style={{ width: "100%", margin: "0px auto" }}
          >
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>Sr. No.</th>
                <th>Offer Period</th>
                {/* <th>Campaign</th> */}
                <th>Product Type for offer</th>
                <th>Buyer Location</th>
                <th>Minimum order value (Rs.)</th>
                <th>Maximum order value (Rs.)</th>
                <th>Discount Percentage (%)</th>
                <th>No. of coupons</th>
                <th>Coupon Codes</th>
                <th>Details</th>
              </tr>

              {offers['apparel'].map((offer) => {
                return <tr>
                  {offer.srNo && <td rowspan={offer.colSpan} style={{ textAlign: "center" }}> {offer.srNo !== "-" ? offer.srNo : ''}</td>}
                  {offer.offerPeriod && <td rowspan={offer.colSpan}>{offer.offerPeriod} </td>}
                  {offer.type && <td rowspan={offer.colSpan}>{offer.type}</td>}
                  {offer.location && <td rowspan={offer.colSpan}> {offer.location} </td>}
                  <td style={{ textAlign: "right" }}>{offer.mov}</td>
                  <td style={{ textAlign: "right" }}>{offer.mxov}</td>
                  <td style={{ textAlign: "right" }}>{offer.discount}</td>
                  <td style={{ textAlign: "center" }}>{offer.noOfCoupons}</td>
                  <td style={{ textAlign: "center" }}>{offer.code}</td>
                  <td>
                    <b>{offer.Details} </b>
                    <br></br>
                    {offer.subDetails}
                  </td>
                </tr>
              })}

            </tbody>
          </table>
        </div>


        <p className="sub-heading">C.	Footwear Products (July Footwear Celebration)</p>
        <div className="table-responsive des-tos">
          <table
            className="table table-bordered"
            style={{ width: "100%", margin: "0px auto" }}
          >
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>Sr. No.</th>
                <th>Offer Period</th>
                {/* <th>Campaign</th> */}
                <th>Product Type for offer</th>
                <th>Buyer Location</th>
                <th>Minimum order value (Rs.)</th>
                <th>Maximum order value (Rs.)</th>
                <th>Discount Percentage (%)</th>
                <th>No. of coupons</th>
                <th>Coupon Codes</th>
                <th>Details</th>
              </tr>

              {offers['footwear'].map((offer) => {
                return <tr>
                  {offer.srNo && <td rowspan={offer.colSpan} style={{ textAlign: "center" }}> {offer.srNo !== "-" ? offer.srNo : ''}</td>}
                  {offer.offerPeriod && <td rowspan={offer.colSpan}>{offer.offerPeriod} </td>}
                  {offer.type && <td rowspan={offer.colSpan}>{offer.type}</td>}
                  {offer.location && <td rowspan={offer.colSpan}> {offer.location} </td>}
                  <td style={{ textAlign: "right" }}>{offer.mov}</td>
                  <td style={{ textAlign: "right" }}>{offer.mxov}</td>
                  <td style={{ textAlign: "right" }}>{offer.discount}</td>
                  <td style={{ textAlign: "center" }}>{offer.noOfCoupons}</td>
                  <td style={{ textAlign: "center" }}>{offer.code}</td>
                  <td>
                    <b>{offer.Details} </b>
                    <br></br>
                    {offer.subDetails}
                  </td>
                </tr>
              })}

            </tbody>
          </table>
        </div>



        <p className="sub-heading">E. Home Furnishing  (Home Furnishing Sale)</p>
        <div className="table-responsive des-tos">
          <table
            className="table table-bordered"
            style={{ width: "100%", margin: "0px auto" }}
          >
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>Sr. No.</th>
                <th>Offer Period</th>
                {/* <th>Campaign</th> */}
                <th>Product Type for offer</th>
                <th>Buyer Location</th>
                <th>Minimum order value (Rs.)</th>
                <th>Maximum order value (Rs.)</th>
                <th>Discount Percentage (%)</th>
                <th>No. of coupons</th>
                <th>Coupon Codes</th>
                <th>Details</th>
              </tr>

              <tr>
                <td rowspan="2" style={{ textAlign: "center" }}>
                  1
                </td>
                <td rowspan="2">
                  1 July 2024 to 31 July 2024
                </td>
                <td rowspan="2">
                  All home furnishing products
                </td>
                <td rowspan="2">
                  All cities
                </td>
                {/* <td rowspan="3" scope="rowgroup">
                  Delhi-NCR, Bangalore, Chennai & Hyderabad
                </td> */}
                <td style={{ textAlign: "right" }}>49,999</td>
                <td style={{ textAlign: "right" }}>99,999</td>
                <td style={{ textAlign: "right" }}>3%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>HDB3</td>
                <td>
                  <b>Flat 3% off on orders worth Rs. 49,999 or more</b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>1,50,000</td>
                <td style={{ textAlign: "right" }}>Unlimited</td>
                <td style={{ textAlign: "right" }}>4%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>HDB4</td>
                <td>
                  <b>Flat 4% off on orders worth Rs. 1,50,000 or more</b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="sub-heading">F.	Consumer Electronics Product (June Tech Bonanza)</p>
        <div className="table-responsive des-tos">
          <table
            className="table table-bordered"
            style={{ width: "100%", margin: "0px auto" }}
          >
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>Sr. No.</th>
                <th>Offer Period</th>
                {/* <th>Campaign</th> */}
                <th>Product Type for offer</th>
                <th>Buyer Location</th>
                <th>Minimum order value (Rs.)</th>
                <th>Maximum order value (Rs.)</th>
                <th>Discount Percentage (%)</th>
                <th>No. of coupons</th>
                <th>Coupon Codes</th>
                <th>Details</th>
              </tr>

              <tr>
                <td
                  style={{ textAlign: "center" }}
                  rowspan="2"
                  scope="rowgroup"
                >
                  1
                </td>
                <td rowspan="2" scope="rowgroup">
                  1 July 2024 to 31 July 2024
                </td>
                <td rowspan="2" scope="rowgroup">
                  Adapters & Chargers
                  Bluetooth Headset
                  Headphone
                  Headsets & Earphones
                  Smart Watch
                  Stylus Pens
                </td>
                <td rowspan="2" scope="rowgroup">
                  All cities
                </td>
                {/* <td rowspan="3" scope="rowgroup">
                  Delhi-NCR, Bangalore, Chennai & Hyderabad
                </td> */}
                <td style={{ textAlign: "right" }}>10,000</td>
                <td style={{ textAlign: "right" }}>19,999</td>
                <td style={{ textAlign: "right" }}>2%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDDS2</td>
                <td>
                  <b>Flat 2% off on orders worth Rs.10,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>20,000</td>
                <td style={{ textAlign: "right" }}>Unlimited</td>
                <td style={{ textAlign: "right" }}>3%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDDS3</td>
                <td>
                  <b>Flat 3% off on orders worth Rs.20,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>


              <tr>
                <td rowspan="2" style={{ textAlign: "center" }}>
                  2
                </td>
                <td rowspan="2">
                  1 July 2024 to 31 July 2024
                </td>
                <td rowspan="2">
                  Memory cards, Power Banks
                </td>
                <td rowspan="2">
                  All cities
                </td>
                {/* <td rowspan="3" scope="rowgroup">
                  Delhi-NCR, Bangalore, Chennai & Hyderabad
                </td> */}
                <td style={{ textAlign: "right" }}>10,000</td>
                <td style={{ textAlign: "right" }}>14,999</td>
                <td style={{ textAlign: "right" }}>1%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDDSMP1</td>
                <td>
                  <b>Flat 1% off on orders worth Rs.10,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>15,000</td>
                <td style={{ textAlign: "right" }}>Unlimited</td>
                <td style={{ textAlign: "right" }}>2%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDDSMP2</td>
                <td>
                  <b>Flat 2% off on orders worth Rs.15,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>

              <tr>
                <td
                  style={{ textAlign: "center" }}
                  rowspan="2"
                  scope="rowgroup"
                >
                  3
                </td>
                <td rowspan="2" scope="rowgroup">
                  1 July 2024 to 31 July 2024
                </td>
                <td rowspan="2" scope="rowgroup">
                  Screen Protectors
                  Case & Covers
                  Data Cables
                </td>
                <td rowspan="2" scope="rowgroup">
                  All cities
                </td>
                {/* <td rowspan="3" scope="rowgroup">
                  Delhi-NCR, Bangalore, Chennai & Hyderabad
                </td> */}
                <td style={{ textAlign: "right" }}>5,000</td>
                <td style={{ textAlign: "right" }}>9,999</td>
                <td style={{ textAlign: "right" }}>3%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDDSCD3</td>
                <td>
                  <b>Flat 3% off on orders worth Rs5,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>10,000</td>
                <td style={{ textAlign: "right" }}>19,999</td>
                <td style={{ textAlign: "right" }}>4%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDDSCD4</td>
                <td>
                  <b>Flat 4% off on orders worth Rs.10,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>

              <tr>
                <td
                  style={{ textAlign: "center" }}
                  rowspan="3"
                  scope="rowgroup"
                >
                  4
                </td>
                <td rowspan="3" scope="rowgroup">
                  1 July 2024 to 31 July 2024
                </td>
                <td rowspan="3" scope="rowgroup">
                  Feature Phones & Smart Phones
                </td>
                <td rowspan="3" scope="rowgroup">
                  All cities
                </td>
                {/* <td rowspan="3" scope="rowgroup">
                  Delhi-NCR, Bangalore, Chennai & Hyderabad
                </td> */}
                <td style={{ textAlign: "right" }}>10,000</td>
                <td style={{ textAlign: "right" }}>24,999</td>
                <td style={{ textAlign: "right" }}>0.5%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDSFP50</td>
                <td>
                  <b>Flat 0.50% off on orders worth Rs10,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>25,000</td>
                <td style={{ textAlign: "right" }}>44,999</td>
                <td style={{ textAlign: "right" }}>0.75%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDSFP75</td>
                <td>
                  <b>Flat 0.75% off on orders worth Rs.25,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>45,000</td>
                <td style={{ textAlign: "right" }}>Unlimited</td>
                <td style={{ textAlign: "right" }}>1%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDSFP1</td>
                <td>
                  <b>Flat 1% off on orders worth Rs.45,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "center" }}>
                  5
                </td>
                <td>
                  1 July 2024 to 31 July 2024
                </td>
                <td>
                  Mobile Holders
                  Mobile Repairing Tools

                </td>
                <td>
                  All cities
                </td>
                {/* <td rowspan="3" scope="rowgroup">
                  Delhi-NCR, Bangalore, Chennai & Hyderabad
                </td> */}
                <td style={{ textAlign: "right" }}>5,000</td>
                <td style={{ textAlign: "right" }}>Unlimited</td>
                <td style={{ textAlign: "right" }}>Rs.500</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>JDSM500</td>
                <td>
                  <b>Flat Rs. 500 off on orders worth Rs. 5,000 or more</b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>


            </tbody>
          </table>
        </div>

        <p className="sub-heading">G.	Toys and Sports (July Dhamaka Sale)</p>
        <div className="table-responsive des-tos">
          <table
            className="table table-bordered"
            style={{ width: "100%", margin: "0px auto" }}
          >
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>Sr. No.</th>
                <th>Offer Period</th>
                {/* <th>Campaign</th> */}
                <th>Product Type for offer</th>
                <th>Buyer Location</th>
                <th>Minimum order value (Rs.)</th>
                <th>Maximum order value (Rs.)</th>
                <th>Discount Percentage (%)</th>
                <th>No. of coupons</th>
                <th>Coupon Codes</th>
                <th>Details</th>
              </tr>

              <tr>
                <td
                  style={{ textAlign: "center" }}
                  rowspan="3"
                  scope="rowgroup"
                >
                  1
                </td>
                <td rowspan="3" scope="rowgroup">
                  1 July 2024 to 31 July 2024
                </td>
                <td rowspan="3" scope="rowgroup">
                  All Toys and Sports products
                </td>
                <td rowspan="3" scope="rowgroup">
                  All cities
                </td>
                {/* <td rowspan="3" scope="rowgroup">
                  Delhi-NCR, Bangalore, Chennai & Hyderabad
                </td> */}
                <td style={{ textAlign: "right" }}>3,000</td>
                <td style={{ textAlign: "right" }}>14,999</td>
                <td style={{ textAlign: "right" }}>1%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>TOYJ1</td>
                <td>
                  <b>Flat 1% off on orders worth Rs.3,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>15,000</td>
                <td style={{ textAlign: "right" }}>29,999</td>
                <td style={{ textAlign: "right" }}>2%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>TOYJ2</td>
                <td>
                  <b>Flat 2% off on orders worth Rs.15,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>30,000</td>
                <td style={{ textAlign: "right" }}>Unlimited</td>
                <td style={{ textAlign: "right" }}>3%</td>
                <td style={{ textAlign: "center" }}>Unlimited</td>
                <td style={{ textAlign: "center" }}>TOYJ3</td>
                <td>
                  <b>Flat 3% off on orders worth Rs.30,000 or more </b>
                  <br></br>
                  Applicable for unlimited times till offer end date.
                </td>
              </tr>
            </tbody>
          </table>
        </div>




        <p className="sub-heading">OTHER OFFER DETAILS AND CONDITIONS:</p>
        <ul className="liparent" style={{ listStyleType: "decimal" }}>
          <li>
            By participating in this scheme, the buying Users acknowledge that the discounts mentioned in the table hereinabove are being offered by the selling Users in their sole discretion. SCR&T has enabled the discounts under the instructions of the Selling Users and shall not have any liability in respect of any schemes, discounts etc. offered by the selling Users.
          </li>
          <li>
            <p>
              The buying User will be eligible to use all the codes indicated above
              only{" "}
              <b>
                <u>during the Offer Period.</u>
              </b>{" "}
              In addition to the Offers mentioned above, the User may be
              eligible for other Offers depending on the number and value of
              transactions successfully undertaken on the Platform. The User
              must select and apply such promotional codes during check-out to
              avail the discount.
            </p>
          </li>
          <li>
            <p>
              Upon completing a transaction of specified value, in the event a
              User receives coupon codes for availing discounts on subsequent
              transactions, the invoice value of such subsequent transactions
              should be for an amount which is equal to or greater than the
              invoice value of the qualifying transaction which was undertaken
              first.
            </p>
          </li>
          <li>
            <p>
              In the event of any newly On-Boarded User, the promotional coupon
              codes may not immediately get activated and such activation may
              take up to 24 hours. During such interim period, the new Users are
              encouraged to contact our sales officers/help desk for receiving
              the relevant promotional codes to be applied for any transaction.
            </p>
          </li>
          <li>
            <p>
              SOLV shall only process orders for products in serviceable pin
              codes listed out in <b>Annexure A</b> below. However it is
              clarified that the attached list of serviceable pin codes is
              tentative and subject to change from time to time without prior
              notice, depending on notification of containment zones on account
              of Covid-19 related restrictions imposed by governmental
              authorities, operational challenges, etc. The Users must seek the
              assistance of relevant SOLV sales officers to ascertain the up to
              date list of serviceable pin codes.
            </p>
          </li>
          <li>
            <p>
              If the User places a purchase order on the Platform, however the
              relevant seller does not accept such purchase order or the order
              is not processed, the User’s discount voucher shall not be
              considered as consumed. Further, if the User places an order for
              certain quantity of products on the Platform exceeding the
              threshold amount of invoice values mentioned above, however the
              order is only partially fulfilled by the seller as a result of
              which the invoice value falls below the threshold amounts, any
              redemption of discount voucher by the User shall be at the
              discretion and in the manner determined by SOLV.
            </p>
          </li>
          <li>
            <p>
              During the Offer Period, payment for orders by way of
              advance/pre-paid mode shall not be available on the Platform for
              availing the Offers.
            </p>
          </li>
          <li>
            <p>
              The Eligible Users shall not be entitled to place reverse pick-up
              orders during the Offer Period. In case an Eligible User orders
              for products and avails the discount, such Eligible User shall not
              be entitled to return the goods. In the case the Eligible User
              wants reverse pick-up services, the discount availed by the User
              shall be forfeited and such amount will be adjusted in the manner
              determined by SOLV.
            </p>
          </li>
          <li>
            <p>
              Any discount voucher once applied by Eligible Users for placing
              orders on the Platform (including in case of cancelled/RTO orders)
              shall be considered as consumed, unless otherwise stated in these
              Terms and Conditions specifically.
            </p>
          </li>
          <li>
            <p>
              In the event any User is eligible for more than one Offer, such
              User shall be entitled to choose the Offer which the User wants to
              avail, subject to the following conditions:
            </p>
            <ol style={{ listStyleType: "upper-roman" }}>
              <li>
                <p>No two Offers can be clubbed for a single order;</p>
              </li>
              <li>
                <p>
                  The relevant seller must receive the full invoice value from
                  the Eligible User;
                </p>
              </li>
              <li>
                <p>
                  In case of COD payments, the Eligible User shall make payment
                  of the invoice amount net of the applicable discount amount.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              The thresholds of minimum invoice amounts which are required to be
              fulfilled by Eligible Users for availing the Offers as set out in
              these Terms and Conditions shall be such amounts net off all
              applicable taxes.
            </p>
          </li>
          <li>
            <p>
              The participation in the Offer is entirely voluntary and it is
              understood that the participation by the Users shall be deemed to
              have been made on a voluntary basis.
            </p>
          </li>
          <li>
            <p>
              In all matters relating to the Offer, including the application of
              discount, the decisions of SOLV shall be final and binding.
            </p>
          </li>
          <li>
            <p>
              Any person availing this Offer shall be deemed to have accepted
              these Terms. The Users shall continue to be bound to comply with
              the Primary Terms and Conditions and these Terms shall be in
              addition to and not in substitution /derogation to the Primary
              Terms and Conditions governing the usage of SOLV services.
            </p>
          </li>
          <li>
            <p>
              SOLV reserves the right to modify at any time, all or any of the
              terms applicable to the Offer, including the terms contained
              herein, without assigning any reasons or without any prior
              intimation whatsoever. SOLV also reserves the right to discontinue
              or terminate the Offer without assigning any reasons or without
              any prior intimation whatsoever at any time during the Offer
              Period.
            </p>
          </li>
          <li>
            <p>
              The Eligible Users shall not be entitled to assign the rights and
              benefits arising under this Offer to any other person. No requests
              for transfer or assignment of the benefits under the Offer shall
              be entertained.
            </p>
          </li>
          <li>
            <p>
              By participating in this Offer, the User expressly agrees that
              SOLV or any of its affiliates will not be liable or responsible
              for any loss or damage whatsoever that you may suffer, directly or
              indirectly, in connection with this Offer, including but not
              limited to that associated with his/her use or delivery or misuse
              of any promotional codes and/or benefit provided hereunder. In the
              event SOLV suffers any loss or damage arising out of or resulting
              from any inaccurate information provided by the User or breach of
              any applicable law or terms and conditions, the User shall be
              liable to indemnify SOLV for all the loss and damage incurred.
            </p>
          </li>
          <li>
            <p>
              Under no circumstances will the benefit being offered under this
              Offer be provided in cash by SOLV, i.e. there are no cash or other
              alternatives available in whole or in part, in relation to the
              benefits under this Offer.
            </p>
          </li>
          <li>
            <p>
              SOLV reserves the right to disqualify any merchant establishment
              or User from the benefits of the Offer and initiate appropriate
              legal action, if any fraudulent activity is identified as being
              carried out for the purpose of availing the benefits under the
              Offer. Further, SOLV reserves the right to deny honouring the
              Offer or any benefit thereof on the grounds of suspicion or abuse
              of the Offer by any User without providing the User any
              explanation thereof.
            </p>
          </li>
          <li>
            <p>
              Any queries or disputes from Users regarding issuance/denial of
              discount, can only be raised within 30 days from the date of
              expiry of Offer Period.
            </p>
          </li>
          <li>
            <p>
              All communication and notices about this Offer should be addressed
              to &nbsp;
              <a className="mail-anchor" href="mailto:cs@solvezy.com">
                cs@solvezy.com
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              All disputes are subject to the exclusive jurisdiction of the
              competent courts/tribunals of Bangalore.
            </p>
          </li>
        </ul>
        <p className="sub-heading text-center" id="annexture-section">
          ANNEXURE A
        </p>
        <p className="sub-heading text-center">SERVICEABLE PIN CODES</p>
        <p>
          1.{" "}
          <a
            className="mail-anchor"
            href={`/FMCG-&-Horeca-serviceable-pincodes-March-Offer.xlsx`}
            download
          >
            Pin codes serviceable for FMCG & HoReCa
          </a>
        </p>

        <p>
          2.{" "}
          <a
            className="mail-anchor"
            href={`/Consumer-Electronics-serviceable-Pincodes-March-Offer.xlsb`}
            download
          >
            Pin codes serviceable for Consumer Electronics
          </a>
        </p>
        <p>
          3.{" "}
          <a
            className="mail-anchor"
            href={`/Apparel-serviceable-Pincodes-March-Offer.xlsx`}
            download
          >
            Pin codes serviceable for Apparel and Fashion
          </a>
        </p>
        <p>
          4.{" "}
          <a
            className="mail-anchor"
            href={`/Home-Furnishings-serviceable-Pincodes-March-Offer.xlsx`}
            download
          >
            Pin codes serviceable for Home Furnishings
          </a>
        </p>
        <p>
          5.{" "}
          <a
            className="mail-anchor"
            href={`/Footwear-serviceable-Pincodes-March-Offer.xlsx`}
            download
          >
            Pin codes serviceable for Footwear
          </a>
        </p>

        <p className="sub-heading text-center" id="annexture-section">
          ANNEXURE B
        </p>

        <p>
          1.{" "}
          <a
            className="mail-anchor"
            href={`/GROCERY-OIL-AND-SUGAR-PRODUCTS.xlsx`}
            download
          >
            GROCERY- OIL AND SUGAR PRODUCTS
          </a>
        </p>


        <p className="sub-heading text-center" id="annexture-section">
          ANNEXURE C
        </p>

        <p>
          1.{" "}
          <a
            className="mail-anchor"
            href={`/FOOD-AND-NON-FOOD-PRODUCTS.xlsx`}
            download
          >
            FOOD AND NON-FOOD PRODUCTS
          </a>
        </p>

      </div>
    </>
  )
}

export default JulyOffer2024Tnc
