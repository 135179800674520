const offers =

{
  "apparel": [{
    "srNo": "1",
    "colSpan": 6,
    "offerPeriod": "1 July 2024 to 31 July 2024",
    "type": "All Apparel products ",
    "location": "All cities ",
    "mov": "5,000",
    "mxov": "14,999",
    "discount": "3%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF3",
    "Details": "Flat 3% off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "mxov": "48,999",
    "discount": "4%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF4",
    "Details": "Flat 4% off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "49,000",
    "mxov": "74,999",
    "discount": "5%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF5",
    "Details": "Flat 5% off on orders worth Rs. 49,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "75,000",
    "mxov": "99,999",
    "discount": "6%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF6",
    "Details": "Flat 6% off on orders worth Rs. 75,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "100,000",
    "mxov": "149,999",
    "discount": "7%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF7",
    "Details": "Flat 7% off on orders worth Rs. 1,00,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "150,000",
    "mxov": "Unlimited",
    "discount": "8%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF8",
    "Details": "Flat 8% off on orders worth Rs. 1,50,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
  ],


  "footwear": [{
    "srNo": "1",
    "colSpan" : 6,
    "offerPeriod": "1 July 2024 to 31 July 2024",
    "type": "All footwear products ",
    "location": "All cities ",
    "mov": "5,000",
    "mxov": "14,999",
    "discount": "1%",
    "noOfCoupons": "Unlimited",
    "code": "JFC1",
    "Details": "Flat 1% off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "mxov": "48,999",
    "discount": "2%",
    "noOfCoupons": "Unlimited",
    "code": "JFC2",
    "Details": "Flat 2% off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "49,000",
    "mxov": "74,999",
    "discount": "4%",
    "noOfCoupons": "Unlimited",
    "code": "JFC4",
    "Details": "Flat 4% off on orders worth Rs. 49,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "75,000",
    "mxov": "149,000",
    "discount": "5%",
    "noOfCoupons": "Unlimited",
    "code": "JFC5",
    "Details": "Flat 5% off on orders worth Rs. 75,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "Above 1,50,000",
    "mxov": "Unlimited",
    "discount": "6%",
    "noOfCoupons": "Unlimited",
    "code": "JFC6",
    "Details": "Flat 5% off on orders worth Rs. 75,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
  ],

  "fmcg": [{
    "srNo": "1",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 13, 15, 17, 22, 24, 29, 31 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHAL100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "2",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 13, 15, 17, 22, 24, 29, 31 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHAL195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "3",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 13, 15, 17, 22, 24, 29, 31 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "312",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHAL312",
    "Details": "Rs.312 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "4",
    "offerPeriod": "2, 9, 18, 25, 27 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "5",
    "offerPeriod": "2, 9, 18, 25, 27 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "202",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH202",
    "Details": "Rs.202 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "6",
    "offerPeriod": "2, 9, 18, 25, 27 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "7",
    "offerPeriod": "5, 12, 16, 19, 20, 23, 26, 30 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRBHAT450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "8",
    "offerPeriod": "5, 12, 16, 19, 20, 23, 26, 30 July 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "30,000",
    "discount": "630",
    "noOfCoupons": "Unlimited",
    "code": "ATTA630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "9",
    "offerPeriod": "2, 3, 4, 6, 9, 10, 11, 13, 17, 24, 31 July 2024 ",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GCLSA100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "10",
    "offerPeriod": "2, 3, 4, 6, 9, 10, 11, 13, 17, 24, 31 July 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GCLSA195",
    "Details": "Rs. 195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "11",
    "offerPeriod": "2, 3, 4, 6, 9, 10, 11, 13, 17, 24, 31 July 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "312",
    "noOfCoupons": "Unlimited",
    "code": "GCLSA312",
    "Details": "Rs.312 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "12",
    "offerPeriod": "1, 8, 15, 18, 20, 22, 25, 27, 29 July 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "13",
    "offerPeriod": "1, 8, 15, 18, 20, 22, 25, 27, 29 July 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "202",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM202",
    "Details": "Rs. 202 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "14",
    "offerPeriod": "1, 8, 15, 18, 20, 22, 25, 27, 29 July 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "15",
    "offerPeriod": "5, 12, 16, 19, 23, 26, 30 July 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCAT450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "16",
    "offerPeriod": "5, 12, 16, 19, 23, 26, 30 July 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "30,000",
    "discount": "630",
    "noOfCoupons": "Unlimited",
    "code": "ATTA630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "17",
    "offerPeriod": "3, 4, 6, 10, 11, 13, 17, 24 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "18",
    "offerPeriod": "3, 4, 6, 10, 11, 13, 17, 24 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA300",
    "Details": "Rs.170 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "19",
    "offerPeriod": "3, 4, 6, 10, 11, 13, 17, 24 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "20",
    "offerPeriod": "2, 9, 16, 18, 20, 23, 25, 27, 30 July 2024 ",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "21",
    "offerPeriod": "2, 9, 16, 18, 20, 23, 25, 27, 30 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "22",
    "offerPeriod": "2, 9, 16, 18, 20, 23, 25, 27, 30 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "23",
    "offerPeriod": "1, 5, 8, 12, 15, 19, 22, 26, 29, 31 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "24",
    "offerPeriod": "1, 5, 8, 12, 15, 19, 22, 26, 29, 31 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA725",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "25",
    "offerPeriod": "1, 5, 8, 12, 15, 19, 22, 26, 29, 31 July 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "35,000",
    "discount": "1015",
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA1015",
    "Details": "Rs.1015 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "26.8.",
    "offerPeriod": "4, 6, 11, 13, 18, 20, 25, 27, July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "27",
    "offerPeriod": "4, 6, 11, 13, 18, 20, 25, 27, July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA300",
    "Details": "Rs. 300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "28",
    "offerPeriod": "4, 6, 11, 13, 18, 20, 25, 27, July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "29",
    "offerPeriod": "1, 2, 3, 8, 9, 10, 15-17, 22-24, 29, 30 July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "30",
    "offerPeriod": "1, 2, 3, 8, 9, 10, 15-17, 22-24, 29, 30 July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA330",
    "Details": "Rs. 330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "31",
    "offerPeriod": "1, 2, 3, 8, 9, 10, 15-17, 22-24, 29, 30 July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "32",
    "offerPeriod": "5, 12, 19, 26, 31 July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "33",
    "offerPeriod": "5, 12, 19, 26, 31 July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA725",
    "Details": "Rs.725 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "34",
    "offerPeriod": "5, 12, 19, 26, 31 July 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "35,000",
    "discount": "1015",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA1015",
    "Details": "Rs.1015 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "35",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024 ",
    "type": "Dry Fruit and Spices",
    "location": "All cities",
    "mov": "1,000",
    "discount": "20",
    "noOfCoupons": "Unlimited",
    "code": "GSF20",
    "Details": "Rs.20 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "36",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "Dry Fruit and Spices",
    "location": "All cities",
    "mov": "2,000",
    "discount": "45",
    "noOfCoupons": "Unlimited",
    "code": "GSF45",
    "Details": "Rs.45 off on orders worth Rs. 2,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "37",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "Dry Fruit and Spices",
    "location": "All cities",
    "mov": "3,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GSF75",
    "Details": "Rs.75 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "38",
    "offerPeriod": "3, 4, 6, 10, 11, 13, 24, 25, 27 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "39",
    "offerPeriod": "3, 4, 6, 10, 11, 13, 24, 25, 27 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "40",
    "offerPeriod": "3, 4, 6, 10, 11, 13, 24, 25, 27 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "41",
    "offerPeriod": "3, 4, 6, 10, 11, 13, 24, 25, 27 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "42",
    "offerPeriod": "1, 2, 8, 9, 15, 16, 22, 23 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "43",
    "offerPeriod": "1, 2, 8, 9, 15, 16, 22, 23 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "44",
    "offerPeriod": "1, 2, 8, 9, 15, 16, 22, 23 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "45",
    "offerPeriod": "1, 2, 8, 9, 15, 16, 22, 23 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "46",
    "offerPeriod": "1, 2, 8, 9, 15, 16, 22, 23 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "47",
    "offerPeriod": "5, 12, 19, 26, 31 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "RDSMS375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "48",
    "offerPeriod": "5, 12, 19, 26, 31 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "RDSMS725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "49",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "50",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "51",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "52",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "53",
    "offerPeriod": "1, 3, 8, 10, 15, 17, 18, 20, 22, 24, 25 27, 29, 31 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "54",
    "offerPeriod": "1, 3, 8, 10, 15, 17, 18, 20, 22, 24, 25 27, 29, 31 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "55",
    "offerPeriod": "1, 3, 8, 10, 15, 17, 18, 20, 22, 24, 25 27, 29, 31 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "56",
    "offerPeriod": "1, 3, 8, 10, 15, 17, 18, 20, 22, 24, 25 27, 29, 31 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "57",
    "offerPeriod": "1, 3, 8, 10, 15, 17, 18, 20, 22, 24, 25 27, 29, 31 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "58",
    "offerPeriod": "2, 5, 9, 12, 16, 19, 23, 26, 30 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "59",
    "offerPeriod": "2, 5, 9, 12, 16, 19, 23, 26, 30 July 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "60",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "General Merchandising",
    "location": "All cities",
    "mov": "1,000",
    "discount": "30",
    "noOfCoupons": "Unlimited",
    "code": "GRGM30",
    "Details": "Rs.30 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "61",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "General Merchandising",
    "location": "All cities",
    "mov": "2,000",
    "discount": "80",
    "noOfCoupons": "Unlimited",
    "code": "GRGM80",
    "Details": "Rs.80 off on orders worth Rs. 2,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "62",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "General Merchandising",
    "location": "All cities",
    "mov": "3,000",
    "discount": "150",
    "noOfCoupons": "Unlimited",
    "code": "GRGM150",
    "Details": "Rs.150 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "63",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29, 30 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "64",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29, 30 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOL140",
    "Details": "Rs.140 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "65",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29, 30 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "66",
    "offerPeriod": "2, 9 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI260",
    "Details": "Rs.260 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "67",
    "offerPeriod": "2, 9 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "20,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOM260",
    "Details": "Rs.260 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "68",
    "offerPeriod": "2, 9 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "69",
    "offerPeriod": "5, 12, 16, 19, 23, 26, 30 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "70",
    "offerPeriod": "5, 12, 16, 19, 23, 26, 30 July 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": "30,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "SCJO450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "71",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 15, 17, 18, 20, 22, 24, 25, 27, 29, 31, July 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "70",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI70",
    "Details": "Rs.70 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "72",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 15, 17, 18, 20, 22, 24, 25, 27, 29, 31, July 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "73",
    "offerPeriod": "1, 3, 4, 6, 8, 10, 11, 15, 17, 18, 20, 22, 24, 25, 27, 29, 31, July 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "74",
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "180",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI180",
    "Details": "Rs.180 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "75",
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "20,000",
    "discount": "240",
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM240",
    "Details": "Rs.240 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "76",
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM390",
    "Details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "77",
    "offerPeriod": "2, 5, 9, 12, 13, 16, 19, 23, 26, 30  July 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "78",
    "offerPeriod": "2, 5, 9, 12, 13, 16, 19, 23, 26, 30  July 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "30,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "SCJO450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "79",
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "5,000",
    "discount": "40",
    "noOfCoupons": "Unlimited",
    "code": "GRBHOS40",
    "Details": "Rs.40 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "80",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Bangalore, Hyderabad",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRBHOS50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "81",
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "5,000",
    "discount": "40",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOS40",
    "Details": "Rs.40 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "82",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOS50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "83",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GDSR100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "84",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GDSR195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "85",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GDSR375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "86",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "35,000",
    "discount": "560",
    "noOfCoupons": "Unlimited",
    "code": "GDSR560",
    "Details": "Rs.560 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "87",
    "offerPeriod": "2, 3, 9, 10, 17, 18, 20 24, 25, 27 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "10,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM175",
    "Details": "Rs.175 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "88",
    "offerPeriod": "2, 3, 9, 10, 17, 18, 20 24, 25, 27 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "278",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM278",
    "Details": "Rs.278 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "89",
    "offerPeriod": "2, 3, 9, 10, 17, 18, 20 24, 25, 27 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "488",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM488",
    "Details": "Rs.488 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "90",
    "offerPeriod": "2, 3, 9, 10, 17, 18, 20 24, 25, 27 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "35,000",
    "discount": "700",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM700",
    "Details": "Rs.700 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "91",
    "offerPeriod": "1, 5, 8, 12, 15, 16, 19, 22, 23, 26, 24-31 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "315",
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "92",
    "offerPeriod": "1, 5, 8, 12, 15, 16, 19, 22, 23, 26, 24-31 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "550",
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "93",
    "offerPeriod": "1, 5, 8, 12, 15, 16, 19, 22, 23, 26, 24-31 July 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": "35,000",
    "discount": "875",
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "94",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "95",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "96",
    "offerPeriod": "4, 6, 11, 13 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "97",
    "offerPeriod": "1, 2, 3, 8-10, 15-18, 20, 22-25, 27, 29 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "10,000",
    "discount": "185",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM185",
    "Details": "Rs.185 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "98",
    "offerPeriod": "1, 2, 3, 8-10, 15-18, 20, 22-25, 27, 29 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "293",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM293",
    "Details": "Rs.293 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "99",
    "offerPeriod": "1, 2, 3, 8-10, 15-18, 20, 22-25, 27, 29 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "500",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM500",
    "Details": "Rs.500 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "100",
    "offerPeriod": "5, 12, 19, 26, 30, 31 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "315",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "101",
    "offerPeriod": "5, 12, 19, 26, 30, 31 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "550",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "102",
    "offerPeriod": "5, 12, 19, 26, 30, 31 July 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "35,000",
    "discount": "875",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "103",
    "offerPeriod": "Not Scheduled Currently",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "104",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "105",
    "offerPeriod": "Not Scheduled Currently",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "106",
    "offerPeriod": "1-6 to 8, 8 to 13, 15 to 20, 22 to 27, 29-31 July 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "107",
    "offerPeriod": "1, 2, 4,6, 8, 9, 11, 15, 16, 18, 20, 22, 23, 25, 27, 29 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHS84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "108",
    "offerPeriod": "1, 2, 4,6, 8, 9, 11, 15, 16, 18, 20, 22, 23, 25, 27, 29 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "109",
    "offerPeriod": "1, 2, 4,6, 8, 9, 11, 15, 16, 18, 20, 22, 23, 25, 27, 29 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHS147",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "110",
    "offerPeriod": "10, 31 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "111",
    "offerPeriod": "10, 31 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "112",
    "offerPeriod": "10, 31 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "35,000",
    "discount": "455",
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "113",
    "offerPeriod": "3, 5, 12, 17, 19, 24, 26, 3 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "25,000",
    "discount": "388",
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU338",
    "Details": "Rs.388 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "114",
    "offerPeriod": "3, 5, 12, 17, 19, 24, 26, 3 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": "35,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU450",
    "Details": "Rs.450 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "115",
    "offerPeriod": "1, 2, 4,6, 8, 9, 11, 15, 16, 18, 20, 22, 23, 25, 27, 29 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "116",
    "offerPeriod": "1, 2, 4,6, 8, 9, 11, 15, 16, 18, 20, 22, 23, 25, 27, 29 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU105",
    "Details": "Rs.105 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "117",
    "offerPeriod": "1, 2, 4,6, 8, 9, 11, 15, 16, 18, 20, 22, 23, 25, 27, 29 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU140",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "118",
    "offerPeriod": "10, 31 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "119",
    "offerPeriod": "10, 31 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "120",
    "offerPeriod": "10, 31 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "35,000",
    "discount": "455",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "121",
    "offerPeriod": "3, 5, 12, 17, 19, 24, 26, 3 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "25,000",
    "discount": "338",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU338",
    "Details": "Rs.338 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "122",
    "offerPeriod": "3, 5, 12, 17, 19, 24, 26, 3 July 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": "35,000",
    "discount": "525",
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU525",
    "Details": "Rs.525 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
  ]
}





export default offers;